import React, { Component } from "react";

// 3rd party

// Components
import Card from "../../Shared/Card";
import Flag from "../../Shared/Flag";
import RouteGenerator from "../../../util/route-generator";
import Link from "next/link";
import styled from "styled-components";
import { colors } from "../../../config/styles";

export default class NewsCardSmall extends Component {
  adjustLink(data) {
    if (data.categorySlug) {
      return {
        as: RouteGenerator.getColumnRoute(data.categorySlug, data.postSlug),
        href: `/colunas/${data.categorySlug}/[slug]`,
      };
    }
    if (data.type === "news") {
      return {
        as: RouteGenerator.getNewsRoute(data.postSlug),
        href: "/noticia/[slug]",
      };
    }
    return {
      as: RouteGenerator.getMatchRoute(data, data.tournament.tournamentName),
      href: "/partida/[id]",
    };
  }
  render() {
    const { data, newTab } = this.props;
    const { as, href } = this.adjustLink(data);
    return (
      <Card noPadding>
        <Link prefetch={false} passHref as={as} href={href}>
          <NewsCardSmallContainer
            target={newTab ? "_blank" : ""}
            rel={newTab ? "noreferrer noopener" : ""}
          >
            {data.postCountry && (
              <NewsFlag country={data.postCountry} size={16} />
            )}
            {!data.postCountry && (
              <PostGameLabel>
                Pós
                <br />
                Jogo
              </PostGameLabel>
            )}
            {!!data.postTitle && <Title>{data.postTitle}</Title>}

            {!data.postTitle && (
              <Title>
                Acompanhe ao vivo {data.teamA.teamName} vs {data.teamB.teamName}{" "}
                na {data.tournament.tournamentName}
              </Title>
            )}
          </NewsCardSmallContainer>
        </Link>
      </Card>
    );
  }
}

const NewsCardSmallContainer = styled.a`
  padding: 12px 16px;
  display: flex;
  transition: all 0.15s;
  align-items: center;
  cursor: pointer;
  margin-bottom: 8px;
  color: ${colors.ACCENT};

  &:hover {
    background: ${colors.ACCENT};
    color: white;
  }
`;

const Title = styled.p`
  flex: 1;
  margin: 0;
  font-size: 14px;

  @media screen and (max-width: 768px) {
    font-size: 32px;
    line-height: 1.5;
  }
`;

const NewsFlag = styled(Flag)`
  margin-right: 12px;
  position: relative;
  top: 1px;

  @media screen and (max-width: 768px) {
    width: 32px;
    margin-right: 24px;
  }
`;

const PostGameLabel = styled.small`
  font-size: 8px;
  font-family: "Roboto", sans-serif;
  font-weight: bold;
  text-transform: uppercase;
  margin-right: 12px;
  position: relative;
  top: 1px;
  line-height: 0.8;

  color: ${colors.GRAY_LIGHT};

  @media screen and (max-width: 768px) {
    font-size: 16px;
  }
`;
