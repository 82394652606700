import dayjs from "dayjs";
import groupBy from "lodash/groupBy";

export default function parseNewsArray(newsArray, groupByDay = true) {
  const finalArray = newsArray.map((news) => {
    return {
      ...news,
      newsDay: dayjs(news.postDate).hour(0).minute(0).second(0).format(),
    };
  });

  if (!groupByDay) return finalArray;

  const groupedByDay = groupBy(
    finalArray.sort((a, b) => {
      return dayjs(a.newsDay).diff(b.newsDay) ? -1 : 1;
    }),
    "newsDay"
  );

  const ordered = Object.keys(groupedByDay)
    .sort((a, b) => (dayjs(a).diff(b) > 0 ? -1 : 1))
    .reduce((obj, key) => {
      obj[key] = groupedByDay[key];
      return obj;
    }, {});

  // Reorder posts by date after grouping by day to avoid wrong order on firefox
  Object.keys(groupedByDay).forEach((key) => {
    groupedByDay[key] = groupedByDay[key].sort((a, b) => {
      return dayjs(a.postDate).diff(b.postDate) ? 1 : -1;
    });
  });

  return ordered;
}
