import React, { Component } from "react";
import { S3_BASE_URL } from "../../config/constants";

export default class Flag extends Component {
  render() {
    const { country, size } = this.props;

    return (
      <img
        {...this.props}
        src={`${S3_BASE_URL}/assets/flags/${country}.svg`}
        alt={country}
        width={size}
        height={size}
        content-visibility="auto"
      />
    );
  }
}
