import React, { Component } from "react";

// Consts
import {
  SPONSORED_NEWS_CATEGORY_SLUG,
  FIXED_NEWS_CATEGORY_SLUG,
  LIVE_NEWS_CATEGORY_SLUG,
} from "../../../config/constants";

// 3rd party

// Components
import Card from "../../Shared/Card";
import Flag from "../../Shared/Flag";
import Link from "next/link";
import styled from "styled-components";
import { colors } from "../../../config/styles";

export default class NewsCard extends Component {
  render() {
    const { data } = this.props;

    const highlightedCategories = [SPONSORED_NEWS_CATEGORY_SLUG.toUpperCase()];
    const liveCategories = [LIVE_NEWS_CATEGORY_SLUG.toUpperCase()];
    const fixedCategories = [FIXED_NEWS_CATEGORY_SLUG.toUpperCase()];

    const flagPlaceholder = "GLOBAL";

    return (
      <Card noPadding>
        <Link
          prefetch={false}
          passHref
          as={
            data.postCategorySlug
              ? `/colunas/${data.postCategorySlug}/${data.postSlug}`
              : `/noticia/${data.postSlug}`
          }
          href={
            data.postCategorySlug
              ? `/colunas/${data.postCategorySlug}/[slug]`
              : "/noticia/[slug]"
          }
        >
          <NewsCardContainer
            isSponsored={data.postCategory.some((category) =>
              highlightedCategories.includes(category.toUpperCase())
            )}
            isLiveNews={data.postCategory.some((category) =>
              liveCategories.includes(category.toUpperCase())
            )}
            isFixedNews={data.postCategory.some((category) =>
              fixedCategories.includes(category.toUpperCase())
            )}
          >
            <NewsImage
              style={{
                backgroundImage: `url(${data.postImage})`,
              }}
            />

            <NewsContent>
              <Category
                isSponsored={data.postCategory.some((category) =>
                  highlightedCategories.includes(category.toUpperCase())
                )}
                isLiveNews={data.postCategory.some((category) =>
                  liveCategories.includes(category.toUpperCase())
                )}
                isFixedNews={data.postCategory.some((category) =>
                  fixedCategories.includes(category.toUpperCase())
                )}
              >
                <CategoryFlag
                  country={
                    data.postCountry ? data.postCountry : flagPlaceholder
                  }
                  size={12}
                />
                {data.postCategory.at(0)}
                {/* {data.postCategory.join(", ")} */}
              </Category>
              <Title>{data.postTitle}</Title>
            </NewsContent>
          </NewsCardContainer>
        </Link>
      </Card>
    );
  }
}

const Title = styled.h2`
  font-size: 20px;
  margin: 0;
  margin-top: 8px;
  margin-bottom: 12px;
  font-family: "Roboto", sans-serif;
  color: ${colors.GRAY_DARK};
  transition: all 0.2s;

  @media screen and (max-width: 768px) {
    font-size: 32px;
  }
`;

const NewsCardContainer = styled.a`
  display: flex;
  min-height: 120px;
  cursor: pointer;
  margin-bottom: 24px;

  ${(props) => {
    if (props.isSponsored) {
      return `
       background-color: ${colors.YELLOW_WARNING_LIGHT};
  border: 1px solid ${colors.ODDS_BETTER_MAIN};

       &:hover ${Title} {
    color: ${colors.ODDS_BETTER_MAIN};
       }
    `;
    } else if (props.isLiveNews) {
      return `
      background-color: ${colors.LIVE_BRIGHTER};
  border: 1px solid ${colors.LIVE};

       &:hover ${Title} {
    color: ${colors.LIVE};
       }
    `;
    } else if (props.isFixedNews) {
      return `
      background-color: ${colors.ACCENT_LIGHTEST_LIGHTER};
  border: 1px solid ${colors.ACCENT};

       &:hover ${Title} {
    color: ${colors.ACCENT};
       }
    `;
    } else {
      return `
        &:hover ${Title} {
    color: ${colors.ACCENT};
    background-color: white;
    border: none;
  }
    `;
    }
  }};

  @media screen and (max-width: 768px) {
    min-height: auto;
  }
`;

const NewsImage = styled.div`
  flex-basis: 35%;
  background: ${colors.GRAY_DARK};
  background-size: cover;
  background-position: center;

  @media screen and (max-width: 768px) {
    display: none;
  }
`;

const NewsContent = styled.div`
  flex-basis: 70%;
  padding: 10px 20px;

  @media screen and (max-width: 768px) {
    flex-basis: 100%;
    display: flex;
    align-items: center;
  }
`;

const Category = styled.small`
  font-size: 9px;
  text-transform: uppercase;
  font-weight: bold;
  padding: 0;

  min-width: 54px;
  min-height: 12px;

  ${(props) => {
    if (props.isSponsored) {
      return `
        background-color: ${colors.ODDS_BETTER_MAIN};
        color: white;
        padding: 5px;
        font-style:italic;

        img {
          display: none;
        }
    `;
    } else if (props.isLiveNews) {
      return `
        background-color: ${colors.LIVE};
        color: white;
        padding: 5px;
            animation: blink 10s infinite both;

        img {
          display: none;
        }
        
@keyframes blink {
  0%,
  50%,
  100% {
    opacity: 1;
  }
  25%,
  75% {
    opacity: 0.7;
  }
}
    `;
    } else if (props.isFixedNews) {
      return `
        background-color: ${colors.ACCENT};
        color: white;
        padding: 5px;

        img {
          display: none;
        }
    `;
    } else {
      return `
        background-color: white;
        color: ${colors.ACCENT};
    `;
    }
  }};

  @media screen and (max-width: 768px) {
    font-size: 0px;
    ${(props) => {
      if (props.isSponsored) {
        return `
        display: none;
    `;
      } else if (props.isLiveNews) {
        return `
        padding: 0;
        margin-right: 25px;
        margin-left: 5px;
        background-color: ${colors.LIVE};

  min-width: 16px;
  min-height: 16px;
  border-radius: 16px;
        
    `;
      } else if (props.isFixedNews) {
        return `
        display: none;
    `;
      } else {
        return `
        background-color: white;
        color: ${colors.ACCENT};
    `;
      }
    }};
  }
`;

const CategoryFlag = styled(Flag)`
  margin-right: 4px;
  position: relative;
  top: 2px;

  @media screen and (max-width: 768px) {
    width: 30px;
    margin-right: 24px;
  }
`;
