import React, { Component, Fragment } from "react";
import dayjs from "dayjs";
import isToday from "dayjs/plugin/isToday";
import isYesterday from "dayjs/plugin/isYesterday";
import isTomorrow from "dayjs/plugin/isTomorrow";

// 3rd
import LazyLoad from "react-lazyload";
import { Row } from "styled-bootstrap-grid";

// components
import PlaceHolder from "../../Shared/Placeholder";
import PlayByPlayNewsCard from "./PlayByPlayNewsCard";
import NewsCard from "./NewsCard";
import NewsCardSmall from "./NewsCardSmall";

// services
import NewsService from "../../../services/news";

// utils
import Card from "../../Shared/Card";

// css
import parseNewsArray from "../../../util/parse-news-arrays";
import styled, { css } from "styled-components";
import { colors } from "../../../config/styles";
import Pagination from "./Pagination";
import injectSponsoredNews from "../../../util/inject-sponsored-news";

class NewsList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      newsList:
        props.initialData && props.initialData.length > 0
          ? injectSponsoredNews(
              parseNewsArray(props.initialData, true),
              props.sponsored
            )
          : [],
      amount: props.amount,
      page: 1,
      totalPages: props.totalPages || 1,
    };
  }

  componentDidMount() {
    const { initialData } = this.props;

    const { newsList } = this.state;

    if (initialData || Object.keys(newsList).length > 0) {
      return;
    }

    this.getNewsPage({ page: this.page });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.initialData !== this.props.initialData) {
      this.setState({
        isLoading: false,
        newsList:
          this.props.initialData && this.props.initialData.length > 0
            ? injectSponsoredNews(
                parseNewsArray(this.props.initialData, true),
                this.props.sponsored
              )
            : [],
        amount: this.props.amount,
        page: 1,
        totalPages: this.props.totalPages || 1,
      });
    }

    if (prevProps.searchQuery !== this.props.searchQuery) {
      this.getNewsPage({ page: 1 });
    }
  }

  getNewsPage = async ({ page }) => {
    const { totalPages, amount } = this.props;

    if (!totalPages || !amount) return;

    if (page > 0 && page <= this.state.totalPages) {
      const { tournament, team, category, searchQuery } = this.props;

      const { isLoading } = this.state;

      if (!isLoading) this.setState({ isLoading: true });

      const newsRes = await NewsService.getNews({
        page,
        amount,
        tournaments: tournament,
        categories: category,
        teams: team,
        query: searchQuery,
      });

      const { totalPages, totalItems, news } = newsRes.data.data;

      let groupedByDay = [];

      if (news.length > 0) groupedByDay = parseNewsArray(news, true);

      this.setState({
        newsList: groupedByDay,
        isLoading: false,
        page,
        totalPages,
      });
    }
  };

  newsTypeRender = (news, small, cardClass) => {
    if (small) {
      return <NewsCardSmall className={cardClass} data={news} />;
    }

    if (news.type === "playByPlay") {
      return <PlayByPlayNewsCard className={cardClass} data={news} />;
    }

    if (news.type === "news") {
      return <NewsCard className={cardClass} data={news} />;
    }

    return "";
  };

  render() {
    const { isLoading, newsList, page, totalPages, amount } = this.state;

    const { small, cardClass, hideDates } = this.props;

    return (
      <Fragment>
        {/* ===================== */}
        {/* EMPTY                 */}
        {/* ===================== */}
        {!isLoading && newsList.length === 0 && (
          <Card className={cardClass}>Nenhuma notícia encontrada</Card>
        )}

        {/* ===================== */}
        {/* LOADING               */}
        {/* ===================== */}
        {isLoading && !small && (
          <Fragment>
            <PlaceHolder height="17px" width="217px" className={cardClass} />
            <PlaceHolder height="131px" width="100%" className={cardClass} />
            <PlaceHolder height="17px" width="217px" className={cardClass} />
            <PlaceHolder height="131px" width="100%" className={cardClass} />
            <PlaceHolder height="131px" width="100%" className={cardClass} />
          </Fragment>
        )}
        {isLoading && small && (
          <Fragment>
            <PlaceHolder height="41px" width="100%" className={cardClass} />
            <PlaceHolder height="41px" width="100%" className={cardClass} />
            <PlaceHolder height="41px" width="100%" className={cardClass} />
            <PlaceHolder height="41px" width="100%" className={cardClass} />
            <PlaceHolder height="41px" width="100%" className={cardClass} />
            <PlaceHolder height="41px" width="100%" className={cardClass} />
          </Fragment>
        )}

        {/* ===================== */}
        {/* NEWS LIST             */}
        {/* ===================== */}
        {!isLoading && Object.keys(newsList).length > 0 && (
          <Fragment>
            {Object.keys(newsList).map((key) => (
              <Fragment key={key}>
                {!hideDates && (
                  <NewsListDate>
                    {dayjs.extend(isYesterday)}
                    {dayjs.extend(isToday)}
                    {dayjs.extend(isTomorrow)}

                    {dayjs(new Date(key)).isYesterday() &&
                      dayjs(new Date(key)).format(
                        "[Ontem], D [de] MMMM [de] YYYY"
                      )}

                    {dayjs(new Date(key)).isToday() &&
                      dayjs(new Date(key)).format(
                        "[Hoje], D [de] MMMM [de] YYYY"
                      )}

                    {dayjs(new Date(key)).isTomorrow() &&
                      dayjs(new Date(key)).format(
                        "[Amanhã], D [de] MMMM [de] YYYY"
                      )}

                    {!dayjs(new Date(key)).isToday() &&
                      !dayjs(new Date(key)).isYesterday() &&
                      !dayjs(new Date(key)).isTomorrow() &&
                      dayjs(new Date(key)).format(
                        "dddd, D [de] MMMM [de] YYYY"
                      )}
                  </NewsListDate>
                )}

                {/* ===================== */}
                {/* NEWS LIST             */}
                {/* ===================== */}
                {newsList[key].map((news) =>
                  this.newsTypeRender(news, small, cardClass)
                )}
              </Fragment>
            ))}

            {!!totalPages && !!amount && (
              <Pagination
                totalPages={totalPages}
                currentPage={page}
                onPrevPage={() => {
                  // window.scroll({ top: 0, left: 0 });
                  this.getNewsPage({ page: page - 1 });
                }}
                onNextPage={() => {
                  // window.scroll({ top: 0, left: 0 });
                  this.getNewsPage({ page: page + 1 });
                }}
              />
            )}
          </Fragment>
        )}
      </Fragment>
    );
  }
}

const NewsListDate = styled.p`
  font-size: 14px;
  font-weight: bold;

  @media screen and (max-width: 768px) {
    font-size: 28px;
    line-height: 28px;
  }
`;

const ArrowCSS = css`
  background: transparent;
  border: none;
  color: ${(props) => (props.isActive ? colors.ACCENT : colors.GRAY_LIGHTER)};
  padding: 5px;
  margin: 5px;
`;

const ArrowLesser = styled.button`
  ${ArrowCSS}
  margin-right: auto;
`;

const ArrowGreater = styled.button`
  ${ArrowCSS}
  margin-left: auto;
`;

export default NewsList;
