import React, { Component } from "react";
import styled, { keyframes } from "styled-components";
import { colors } from "../../config/styles";

class PlaceHolder extends Component {
  render() {
    const { height, width, className } = this.props;

    return (
      <PlaceholderContainer
        className={className}
        height={height}
        width={width}
      />
    );
  }
}

const opacityLoop = keyframes`
  0%{ opacity: 0.1; }
  100%{ opacity: 1; }
`;

const PlaceholderContainer = styled.div`
  animation-duration: 1s;
  animation-direction: alternate;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  animation-name: ${opacityLoop};
  background: ${colors.GRAY_LIGHTER};
  height: ${(props) => props.height};
  width: ${(props) => props.width};
  border-radius: 5px;
  margin-bottom: 8px;
`;

export default PlaceHolder;
