import slugify from "slugify";

export default class RouteGenerator {
  static getTournamentRoute(tournamentId, tournamentName) {
    if (!tournamentId || !tournamentName) return "";
    return `/campeonato/${tournamentId}-${slugify(tournamentName)}`;
  }

  static getMatchRoute(match, tournamentName = "") {
    if (!match) return "";
    if (!!match.isVzone) return `${match.urlVzone}`;
    if (
      !match.matchId ||
      !match.teamA.teamName ||
      !match.teamB.teamName ||
      (tournamentName !== ""
        ? !tournamentName
        : !match.tournament.tournamentName)
    ) {
      return "";
    }

    return `/partida/${match.matchId}-${slugify(
      match.teamA.teamName
    )}-vs-${slugify(match.teamB.teamName)}-${
      tournamentName
        ? slugify(tournamentName)
        : slugify(match.tournament.tournamentName)
    }`;
  }

  static getOldMatchRoute(match, tournamentName = "") {
    if (
      !match.matchId ||
      !match.teamA.name ||
      !match.teamB.name ||
      (tournamentName !== "" ? !tournamentName : !match.tournament.name)
    )
      return "";
    return `/partida/${match.matchId}-${slugify(match.teamA.name)}-vs-${slugify(
      match.teamB.name
    )}-${
      tournamentName ? slugify(tournamentName) : slugify(match.tournament.name)
    }`;
  }

  static getTeamRoute(teamId, teamName) {
    if (!teamId || !teamName) return "";

    return `/equipe/${teamId}-${slugify(teamName)}`;
  }

  static getNewsRoute(postSlug) {
    if (!postSlug) return "";

    return `/noticia/${postSlug}`;
  }

  static getColumnRoute(authorSlug, postSlug) {
    if (!authorSlug && !postSlug) return "";
    return `/colunas/${authorSlug}/${postSlug}`;
  }

  static getLiveRoute(streamChannel) {
    if (!streamChannel) return "";

    return `/lives/${streamChannel}`;
  }

  static getBetHouseRoute(slug) {
    if (!slug) return "";

    return `/casa-de-aposta/${slug}`;
  }
}
