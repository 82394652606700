import axios from "axios";
import { API_BASE_URL } from "../config/constants";

export default class NewsService {
  static async getNews({
    page,
    amount,
    categories,
    tournaments,
    teams,
    query,
    playByPlay,
  }) {
    const url = `${API_BASE_URL}/news`;
    const params = {
      page,
      amount,
      "categories[]": categories,
      tournaments: tournaments,
      teams: teams,
      query,
      playByPlay,
    };

    try{
      const res = await axios.get(url, {
        params,
      });
      
      return res;
    }catch(error){
      console.error("Error on get news", error.message)
      return { data:{ data: [] } }
    }
  }

  static async getSingleNews(slug) {
    try{
      const url = `${API_BASE_URL}/news/${slug}`;
      
      return await axios.get(url);
    }catch(error){
      console.error("Error on get single news", error.message)
      throw new Error("News not found");
    }
  }

  static RANGES = {
    MONTH: "/news/popular/month",
    WEEK: "/news/popular/week",
    DAY: "/news/popular/day",
  };

  // static getPopularNews(range) {
  //   const url = `${API_BASE_URL}${range}`;

  //   return axios.get(url);
  // }
}
