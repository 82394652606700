import styled from "styled-components";
import { colors } from "../../../config/styles";
import Button, { buttonSizes, buttonThemes } from "../../Shared/Button";

const Pagination = ({ totalPages, currentPage, onPrevPage, onNextPage }) => (
  <PaginationContainer>
    <Button
      text="Anterior"
      onClick={onPrevPage}
      theme={buttonThemes.default.MAIN}
      size={buttonSizes.SMALL}
      disabled={currentPage <= 1}
    />
    <span>
      Pagina {currentPage} de {totalPages}
    </span>
    <Button
      text="Próxima"
      onClick={onNextPage}
      theme={buttonThemes.default.MAIN}
      size={buttonSizes.SMALL}
      disabled={totalPages <= currentPage}
    />
  </PaginationContainer>
);

const PaginationContainer = styled.div`
  margin: 16px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;

  span {
    font-size: 12px;
    color: ${colors.GRAY};
  }

  @media screen and (max-width: 768px) {
    span {
      font-size: 24px;
    }
  }
`;

export default Pagination;
